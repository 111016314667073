import { AxiosError } from "axios"

export const defaultErrorHandler = (error: any, action?: string) => {
  if (error.response?.status) {
    if (error.response.status === 403) return `Permission denied on ${action}`
  }

  return `Unexpected error occurred${action ? "" : ` while ${action}`}`
}

export const errorToText = (
  error: any,
  defaultMessage: string = "Unexpected error"
) => {
  if (error instanceof AxiosError) {
    const response = error.response?.data
    let failureInfo = ""
    if (response?.failures?.length > 0) {
      failureInfo = response.failures
        .map((failure: { field: string; message: string }) => {
          return `${failure.field}: ${failure.message}`
        })
        .join(", ")
    }
    if (typeof response.error?.message === "string")
      return (response.error?.message || "") + failureInfo
  }

  if (error instanceof Error && error.message) return error.message

  return defaultMessage
}

export default defaultErrorHandler
