import { observer } from "mobx-react-lite"
import React from "react"
import throttle from "lodash/throttle"

import AlertBanner from "@components/ui/AlertBanner/AlertBanner"
import { useUnstructuredSearchEntity } from "@pages/search/SearchContext/SearchResultContext"
import { useController, useStore } from "@store"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import { ErrorMessage } from "@framework/types/common"

import RetryButton from "./RetryControl"
import AnswerSummary from "./AnswerSummary"

import styles from "./AnswerSummary.module.sass"

type AnswerSectionProps = {
  defaultQuery?: boolean // TODO get rid of prop drilling
}

export const AnswerSection: React.FC<AnswerSectionProps> = observer(
  ({ defaultQuery }) => {
    const { factFinderSolutionController } = useController()
    const { factFinderSolutionStore } = useStore()
    const { searchEntity } = useUnstructuredSearchEntity()

    const isLatest = factFinderSolutionStore.searchFlowStore.isLatestEntity(
      searchEntity.id
    )

    const isAutoRetry = searchEntity.retryAttempt < 1

    const handleRetry = React.useCallback(
      throttle(
        () => factFinderSolutionController.retrySearch(searchEntity.id),
        2_000
      ),
      [searchEntity.id]
    )

    const handleTimeUp = () => {
      if (isAutoRetry && isLatest) {
        handleRetry()
      }
    }

    const timeout = 7 // seconds

    const renderErrorStatus = (error: ErrorMessage) => {
      if (error.status === "RATE_LIMIT")
        return `It looks like you’ve hit the rate limit. Don’t worry - just give it ${timeout} seconds and try again. We’ll be ready when you are.`

      return `Don’t worry - just give it ${timeout} seconds and try again. We’ll be ready when you are.`
    }

    return (
      <div className={styles.answerSection}>
        <AnswerSummary defaultQuery={defaultQuery} />

        {searchEntity.error ? (
          <>
            <AlertBanner type="error">
              {searchEntity.error.message}

              <Text variant="caption1" color="text70Color">
                It looks like we’ve hit a snag.
              </Text>
              {isLatest ? (
                <>
                  <Text variant="caption1" color="text70Color">
                    {renderErrorStatus(searchEntity.error)}
                  </Text>

                  <Text
                    variant="caption1"
                    color="text50Color"
                    style={{ marginTop: "8px" }}
                  >
                    If this keeps happening, feel free to reach out to our
                    support team.
                  </Text>

                  <Templates.Header
                    offsetTop="middle"
                    left={
                      <RetryButton
                        delay={timeout}
                        onTimeUp={handleTimeUp}
                        onRetry={handleRetry}
                      >
                        {isAutoRetry ? "Auto retry in..." : "Try Again"}
                      </RetryButton>
                    }
                  />
                </>
              ) : null}
            </AlertBanner>
          </>
        ) : null}
      </div>
    )
  }
)

export default AnswerSection
