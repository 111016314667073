import * as React from "react"
import clsx from "clsx"

import { FloatingFocusManager, FloatingPortal } from "@floating-ui/react"

import Box from "../Dropdown/Box"
import { useDropdownContext } from "./DropdownContext"

import styles from "./Select.module.sass"

export const DropdownContainer: React.FC = ({ children }) => {
  const context = useDropdownContext()

  const { floating, interactions } = context

  if (!context.open) return null
  return (
    <FloatingPortal root={document.body}>
      <FloatingFocusManager
        context={floating.context}
        modal={false}
        initialFocus={-1}
        disabled={context.disabled}
      >
        <Box
          ref={floating.refs.setFloating}
          color="primary"
          className={clsx(styles.dropdownBox, {
            [styles.disabled]: context.disabled,
          })}
          {...interactions.getFloatingProps({
            style: {
              ...floating.floatingStyles,
              zIndex: 100,
            },
            onMouseDown(e) {
              e.preventDefault()
            },
          })}
        >
          {children}
        </Box>
      </FloatingFocusManager>
    </FloatingPortal>
  )
}

export default DropdownContainer
