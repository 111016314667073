import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { UserAndGroup } from "@framework/types/user"
import { useController, useStore } from "@store"
import Loader from "@components/ui/Loader/BarLoader"
import Switch from "@components/ui/Switch/Switch"
import { Option } from "@framework/types/utils"
import Label from "@components/ui/Label/Label"
import { SearchContext } from "@components/prototypes/SearchContext"
import { SearchContextInput } from "@components/prototypes/SearchContext/SearchContextInput"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"
import AddParticipantForm from "./AddParticipantForm"
import SolutionParticipantList from "./SolutionParticipantList"
import useSelectedEntities from "./useSelectedEntities"

import styles from "./AccessPermissionModal.module.sass"

const permissionOptions: Option[] = [
  {
    name: "public",
    value: "Public",
  },
  {
    name: "private",
    value: "Private",
  },
]

export interface SolutionAccessPermissionModalProps {
  solutionId: string
  initiallyPublic?: boolean
  onClose?: () => void
}

const SolutionAccessPermissionModal: React.FC<SolutionAccessPermissionModalProps> =
  observer(({ solutionId, initiallyPublic = false, onClose }) => {
    const alert = useAlert()

    const modalContext = useModal(ModalsTypes.SOLUTION_ACCESS_PERMISSIONS_MODAL)

    const {
      solutionsStore: { permissions },
    } = useStore()

    const { solutionsController } = useController()

    const [isPublicInitially, setPublicInitially] =
      React.useState<boolean>(initiallyPublic)
    const [isPublic, setPublic] = React.useState<boolean>(isPublicInitially)
    const [addingMode, setAddingMode] = React.useState(false)
    const [query, setQuery] = React.useState("")

    const entitiesToRemove = useSelectedEntities<UserAndGroup>()

    const searchContext = React.useMemo(() => ({ query, setQuery }), [query])

    const handleSwitchToPublic = async () => {
      const success = await solutionsController.switchSolutionAccessToPublic(
        solutionId
      )

      if (!success) {
        alert.error("Failed to make solution public")
        return false
      }

      alert.success("Successfully updated permissions")
      setPublicInitially(true)
      return true
    }

    const handleRemove = async () => {
      const success = await solutionsController.withdrawAccess(
        solutionId,
        entitiesToRemove.getSelectedEntities()
      )

      if (!success) {
        alert.error("Failed to update permissions")
        return false
      }

      alert.success("Successfully updated permissions")
      solutionsController.getSolutionPermissions(solutionId)
      return true
    }

    const handleSubmit = async () => {
      const success = await (isPublic ? handleSwitchToPublic : handleRemove)()

      if (!success) return

      setPublicInitially(isPublic)
      entitiesToRemove.reset()
      searchContext.setQuery("")
    }

    const handleAdd = async (entities: UserAndGroup[]) => {
      const success = await solutionsController.grantAccess(
        solutionId,
        entities
      )

      if (!success) {
        alert.error("Failed to add users to solution")
        return false
      }

      alert.success("Successfully updated permissions")
      searchContext.setQuery("")
      setAddingMode(false)
      solutionsController.getSolutionPermissions(solutionId, true)
      return true
    }

    const handleClose = () => {
      modalContext.hideModal()
      onClose?.()
    }

    const handleCancel = () => {
      entitiesToRemove.reset()
      setPublic(isPublicInitially)
    }

    React.useEffect(() => {
      solutionsController.getSolutionPermissions(solutionId, true)
    }, [])

    const isLoading = permissions.isLading
    const isTouched = isPublic !== isPublicInitially || !!entitiesToRemove.total

    return (
      <BaseModal
        className={styles.root}
        title={<ModalTitle titleText="Access Permission" />}
        onClose={handleClose}
      >
        <SearchContext.Provider value={searchContext}>
          {addingMode ? (
            <AddParticipantForm
              onSubmit={handleAdd}
              onCancel={() => setAddingMode(false)}
              excludeByRelation="solution"
              relationId={solutionId}
              loading={isLoading}
            />
          ) : (
            <div className={styles.bodyContainer}>
              <Label id="mode" label="Access Mode">
                <Switch
                  items={permissionOptions}
                  checked={isPublic ? "public" : "private"}
                  onChange={(v) => setPublic(v === "public")}
                />
              </Label>

              {isPublic ? (
                <Text
                  variant="body2"
                  color="text70Color"
                  className={styles.notation}
                >
                  <Icon name="info" />
                  {isTouched
                    ? "Access to the solution will be granted to all users in the system once confirmed"
                    : "All users in the system has access to the solution"}
                </Text>
              ) : (
                <div className={styles.listContainer}>
                  <div>
                    <Label
                      id="search-users"
                      label="Users and Groups with access"
                    />

                    <div className={styles.searchWrapper}>
                      <SearchContextInput className={styles.searchInput} />

                      {!entitiesToRemove.total && (
                        <Button
                          variant="outlined"
                          color="default"
                          size="big"
                          before={<Icon name="plus" />}
                          onClick={() => setAddingMode(true)}
                        >
                          Add people
                        </Button>
                      )}
                    </div>
                  </div>

                  <SolutionParticipantList
                    onSelect={entitiesToRemove.select}
                    isSelected={entitiesToRemove.isSelected}
                  />
                </div>
              )}

              <ModalFooterContainer>
                {isTouched ? (
                  <>
                    <Button
                      size="big"
                      variant="outlined"
                      disabled={isLoading}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      after={isLoading && <Loader size="small" />}
                      size="big"
                      color="primary"
                      disabled={isLoading}
                      onClick={handleSubmit}
                    >
                      Confirm
                    </Button>
                  </>
                ) : (
                  <Button size="big" variant="outlined" onClick={handleClose}>
                    Done
                  </Button>
                )}
              </ModalFooterContainer>
            </div>
          )}
        </SearchContext.Provider>
      </BaseModal>
    )
  })

export default SolutionAccessPermissionModal
