import React, { useState } from "react"
import debounce from "lodash/debounce"

import useEvent from "./useEvent"

export const useDebounced = <T,>(callback: (value: T) => void, delay = 500) => {
  const callbackEvent = useEvent(callback)

  return React.useCallback(
    debounce((newValue: T) => callbackEvent(newValue), delay),
    [delay]
  )
}

export default useDebounced
