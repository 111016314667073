import { makeAutoObservable } from "mobx"

import {
  CreateContentSourceParams,
  KibanaConnector,
  KibanaConnectorInfo,
  ConnectorDocument,
  SyncJobInfo,
  ManualUploadFileInfo,
  WebsiteInfo,
  ConnectorDocumentsFilters,
} from "@framework/types/content-manager"
import { QueryFilterData } from "@framework/types/query-filter"
import { PaginationListMeta } from "@framework/types/utils"
import { Company } from "@framework/types/company"
import { ProductCategory } from "@store/product/product.store"

class ContentManagerStore {
  filters: ConnectorDocumentsFilters = {}

  contentTypes: QueryFilterData[] = []

  kibanaConnectorAccounts: KibanaConnector[] = []

  kibanaConnectorsCount: KibanaConnectorInfo[] | null = null

  createdContentSource: CreateContentSourceParams | null = null

  documentsList: ConnectorDocument[] = []

  syncJobs: SyncJobInfo[] = []

  documentsMeta: PaginationListMeta | null = null

  companies: Company[] = []

  categories: ProductCategory[] = []

  websiteInfos: WebsiteInfo[] = []

  loadingKibanaConnectorAccounts = false

  loadingKibanaConnectorsCount = false

  loadingCreatedContentSource = false

  loadingDocumentsList = false

  loadingSyncJobs = false

  loadingUpdateContentTypes = false

  loadingCompanies = false

  error: string | null = null

  uploads: Record<string, ManualUploadFileInfo> = {}

  loadingCategories = false

  errorCategories: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get filterStr() {
    return Object.keys(this.filters).length > 1
      ? JSON.stringify(this.filters)
      : undefined
  }

  setActiveFilter = (filters: ConnectorDocumentsFilters) => {
    this.filters = { ...filters }
    this.documentsList = []
  }

  addUpload = (id: string, uploadInfo: ManualUploadFileInfo) => {
    this.uploads[id] = uploadInfo
  }

  updateUpload = (id: string, updateInfo: ManualUploadFileInfo) => {
    if (this.uploads[id]) {
      this.uploads[id] = { ...this.uploads[id], ...updateInfo }
    }
  }
}

export default ContentManagerStore
