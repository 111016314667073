import React from "react"
import { observer } from "mobx-react-lite"

import Chip from "@components/ui/Chip/Chip"
import Avatar from "@components/ui/Avatar/Avatar"
import Text from "@components/ui/Typography/Text"
import { SimpleBaseUserData } from "@framework/types/user"
import { getFullName } from "@pages/questions/utils"

const UserChip: React.FC<{
  data?: SimpleBaseUserData | null
  isLoading?: boolean
}> = observer(({ data, isLoading = false }) => {
  if (data == null && isLoading)
    return (
      <Text variant="caption1" color="text100Color">
        -
      </Text>
    )

  if (data == null)
    return (
      <Chip
        variant="rounded"
        color="secondary"
        before={<Avatar />}
        title="Deactivated user"
      >
        Deactivated user
      </Chip>
    )

  const fullName = getFullName(data)
  const displayName = fullName || "Deactivated user"

  return (
    <Chip
      variant="rounded"
      color="secondary"
      before={<Avatar src={data.avatarURL} name={fullName} />}
      title={displayName}
    >
      {displayName}
    </Chip>
  )
})

export default UserChip
