import { AxiosResponse } from "axios"

import {
  CompanyWithMetaInfo,
  CreateCompanyRequest,
  LinkCompanyRequest,
} from "@framework/types/company"
import { Permission, SolutionPermission } from "@framework/types/user"
import { PaginationListMeta, PaginationParams } from "@framework/types/utils"

import HttpService from "./http.service"
import { SortByParams } from "./common/types"

export interface GetUserAndGroupsResponse {
  data: Permission[]
  meta: PaginationListMeta
}

class CompanyAPI extends HttpService {
  getCompanies = (
    options?: SortByParams
  ): Promise<AxiosResponse<CompanyWithMetaInfo[]>> => {
    return this.get("ts/v2/companies", true, options)
  }

  getTrialInstanceCompanies = (): Promise<
    AxiosResponse<CompanyWithMetaInfo[]>
  > => {
    return this.get("ts/v2/companies/public")
  }

  createCompany = (
    data: CreateCompanyRequest
  ): Promise<AxiosResponse<CompanyWithMetaInfo>> => {
    return this.post("ts/v2/companies", data)
  }

  updateCompany = (
    data: CreateCompanyRequest,
    id: string
  ): Promise<AxiosResponse<CompanyWithMetaInfo>> => {
    return this.patch(`ts/v2/companies/${id}`, data)
  }

  linkCompanies = (data: LinkCompanyRequest): Promise<AxiosResponse<any>> => {
    return this.post("ts/v2/companies/link", data)
  }

  startAttributeExtraction = (
    companyId: string
  ): Promise<AxiosResponse<any>> => {
    return this.post("ts/pims/products/attributes/tag", { companyId })
  }

  getCompanyPermissions = (
    companyId: string,
    params: PaginationParams
  ): Promise<AxiosResponse<GetUserAndGroupsResponse>> => {
    return this.get(`ts/users-permissions/company/${companyId}`, true, params)
  }

  grantAccess = (params: {
    companyIds: string[]
    userIds: string[]
    groupIds: string[]
  }): Promise<AxiosResponse<{ data: string }>> =>
    this.post(
      `ts/users-permissions`,
      {
        productIds: [],
        documentIds: [],
        categoryIds: [],
        ...params,
      },
      true
    )

  withdrawAccess = (
    companyId: string,
    params:
      | {
          userIds: string[]
          groupIds: string[]
          makePublic: false
        }
      | { makePublic: true }
  ): Promise<AxiosResponse<{ data: string }>> =>
    this.delete(`ts/users-permissions/company/${companyId}`, params, true)
}

export default new CompanyAPI()
