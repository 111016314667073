import { AxiosResponse } from "axios"
import { nanoid } from "nanoid"
import { from, Observable } from "rxjs"

import {
  BasicCloudFolderEntity,
  ConnectedCloudFolder,
  ConnectingDataConnector,
  DataConnectorFile,
  DataConnectorSourceName,
  DataSourceFile,
  DataSourceGroupName,
  DataSourceName,
  DataSourceStatus,
  DataSourcesList,
  DataSourcesListMeta,
  FileDataSourceName,
  WebsiteSourceName,
  dataConnectorsNames,
  fileSourcesNames,
  webSiteNames,
  dataConnectorContentGroupNames,
  DataConnectorContentGroupName,
  DataConnectorAccountEntity,
  DataSourceStatusReport,
  ConnectedWebsite,
  UploadedFile,
  CloudFolder,
  CloudEntity,
} from "@framework/types/upload"
import {
  CloudFoldersCollectionFractions,
  FileCollectionFractions,
  PaginationListMeta,
  PaginationParams,
  VirtualListChunk,
  VirtualListChunkMeta,
  WebsiteCollectionFractions,
} from "@framework/types/utils"
import { ConnectWebsiteFormType } from "@pages/upload/ConnectWebsite/types"
import { initArray } from "@utils/numberUtils"
import { delay } from "@utils/promise"

import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"
import { mockCloudDirectoryTree } from "./upload.utils"

export interface GetDataSourceStatusReportResponse {
  data: DataSourceStatusReport[] | null
}

export interface GetDataConnectorAccounts {
  data: DataConnectorAccountEntity[]
}

export interface GetConnectedEntitiesResponse {
  data: CloudEntity[]
  meta: VirtualListChunkMeta
  count: CloudFoldersCollectionFractions
}

export interface GetUploadedFilesResponse {
  data: UploadedFile[]
  meta: VirtualListChunkMeta
  count: FileCollectionFractions
}

export interface GetConnectedWebsitesResponse {
  data: ConnectedWebsite[]
  meta: VirtualListChunkMeta
  count: WebsiteCollectionFractions
}

export interface GetCloudFoldersResponse {
  data: CloudFolder[]
  meta: VirtualListChunkMeta
  count: CloudFoldersCollectionFractions
}

export interface GetDataConnectorFilesResponse {
  data: DataSourcesList<DataConnectorFile>
  meta: DataSourcesListMeta
}

export interface GetDataConnector {
  data: DataSourceFile
}

export interface UploadFilesResponse extends DefaultSuccessResponse {
  data: unknown
}

export interface UploadFilesErrorResponse {
  data: any
  status: "INCORRECT_URL" | "BODY_INCOMPLETE"
  message: string
}

export interface ConnectWebsiteResponse extends DefaultSuccessResponse {
  data: {
    status: "SUCCESS"
    website: string
  }[]
}

export interface ConnectWebsiteErrorResponse {
  status: "VALIDATION_FAILED" | "BODY_INCOMPLETE"
}

export interface DeleteDataSourceResponse extends DefaultSuccessResponse {
  data: any
}

export interface GetCloudEntitiesListResponse {
  data: CloudEntity[]
  meta: VirtualListChunkMeta
}

export interface GetCloudFoldersListResponse {
  data: BasicCloudFolderEntity[]
  meta: PaginationParams & {
    nextPageToken?: string | null
  }
}

export interface GetCloudFolderResponse {
  data: ConnectedCloudFolder
}

export interface UpdateCloudFoldersListResponse {
  data: ConnectedCloudFolder[]
}

export interface AddDataConnectorResponse {
  data: ConnectingDataConnector
}

export interface AddDataConnectorBaseRequest {
  name: string
  clientId: string
  clientSecret: string
  connectorType: DataConnectorSourceName
  baseUrl?: string
  region?: string
}

export interface ConnectWithCodeRequest extends AddDataConnectorBaseRequest {
  code: string
  redirectUrl: string
}

export interface ConnectWithTokenRequest extends AddDataConnectorBaseRequest {
  refreshToken: string
  token: string
}

export interface GetFilesByGroupNameResponse {
  data: DataSourceFile[]
  meta: PaginationListMeta
}

export interface GetDataConnectorByCategoryIdResponse {
  data: DataSourceFile[]
}

export interface GetDataSourceGroupStatuesResponse {
  data: DataSourceStatus[]
}

export interface GetSignedURLResponse {
  data: {
    signedUrl: string
  }
}

const GETDataSourceAPIKeyMap: Record<
  DataSourceName | DataSourceGroupName,
  string
> = {
  spaces: "folders",
  folders: "folders",
  page: "file",
  // files
  file: "file",
  manual: "file/manual",
  // website
  website: "website",
  // public
  public: "public",
  // data connectors
  "data-connector": "data-connector",
  smb: "data-connector/smb",
  "network-drive": "data-connector/network-drive",
  box: "data-connector/box",
  "amazon-aws-s3": "data-connector/amazon-aws-s3",
  sharepoint: "data-connector/sharepoint",
  sharepoint_online: "data-connector/sharepoint_online",
  blobstorage: "data-connector/blobstorage",
  azure_blob_storage: "data-connector/azure_blob_storage",
  "microsoft-teams": "data-connector/microsoft-teams",
  "microsoft-one-note": "data-connector/microsoft-one-note",
  "microsoft-one-drive": "data-connector/microsoft-one-drive",
  slack: "data-connector/slack",
  "google-drive": "data-connector/google-drive",
  salesforce: "data-connector/salesforce",
  "atlassian-confluence": "data-connector/atlassian-confluence",
  "atlassian-confluence-data-center":
    "data-connector/atlassian-confluence-data-center",
  "atlassian-jira": "data-connector/atlassian-jira",
  zendesk: "data-connector/zendesk",
}

const findGETDataSourceConnectionsAPIKey = (sourceName: DataSourceName) => {
  const APIKey = GETDataSourceAPIKeyMap[sourceName]
  if (!APIKey) throw Error("Data source connections API key haven't found")
  return APIKey
}

const findDELETEDataSourceGroupAPIKey = (sourceName: DataSourceName) => {
  if (fileSourcesNames.includes(sourceName as FileDataSourceName))
    return { sourceType: "file", idParamName: "id" }
  if (dataConnectorsNames.includes(sourceName as DataConnectorSourceName))
    return { sourceType: "data-connector", idParamName: "dataConnectorId" }
  if (webSiteNames.includes(sourceName as WebsiteSourceName))
    return { sourceType: "website", idParamName: "websiteId" }
  if (
    dataConnectorContentGroupNames.includes(
      sourceName as DataConnectorContentGroupName
    )
  )
    return { sourceType: "data-connector/folder", idParamName: "folderId" }

  throw Error("Data source group API key haven't found")
}

let cloudEntitiesMock: Record<string, CloudEntity[]> | null = null

const getCloudEntitiesMock = async () => {
  if (cloudEntitiesMock == null) {
    cloudEntitiesMock = await mockCloudDirectoryTree()
  }

  return cloudEntitiesMock
}

class UploadAPI extends HttpService {
  getDataSourceStatus$ = (): Observable<
    AxiosResponse<GetDataSourceStatusReportResponse>
  > => this.getStream$(`ts/data/all`)

  getDataConnectorAccounts$ = (
    sourceName: DataConnectorSourceName
  ): Observable<AxiosResponse<GetDataConnectorAccounts>> =>
    this.getStream$(
      `ts/data/${findGETDataSourceConnectionsAPIKey(sourceName)}/all`
    )

  getUploadedFiles$ = (
    params: VirtualListChunk & { query?: string } & any
  ): Observable<AxiosResponse<GetUploadedFilesResponse>> =>
    this.getStream$(`ts/data/file/manual/all`, { params })

  getConnectedWebsites$ = (
    params: VirtualListChunk & { query?: string } & any
  ): Observable<AxiosResponse<GetConnectedWebsitesResponse>> =>
    this.getStream$(`ts/data/website/all`, { params })

  getCloudFolders$ = (
    dataConnectorId: string,
    params: VirtualListChunk & { query?: string } & any
  ): Observable<AxiosResponse<GetCloudFoldersResponse>> => {
    return this.getStream$(
      `ts/data/data-connector/${dataConnectorId}/folder/all`,
      { params }
    )
  }

  getCloudEntitiesList$ = (
    dataConnectorId: string,
    params: VirtualListChunk & { query?: string } & any
  ): Observable<AxiosResponse<GetCloudEntitiesListResponse>> => {
    return this.getStream$(`ts/v2/data-connector/cloud-directories`, {
      params: { ...params, dataConnectorId },
    })
  }

  getCloudEntitiesListMock$ = (
    dataConnectorId: string,
    params: VirtualListChunk & { query?: string; path?: string }
  ): Observable<{ data: GetCloudEntitiesListResponse }> => {
    return from(
      new Promise<{ data: GetCloudEntitiesListResponse }>((resolve) => {
        getCloudEntitiesMock().then((data) => {
          const allListMock = data?.[params.path ?? ""] ?? []

          resolve({
            data: {
              data: allListMock.slice(
                params.offset,
                params.offset + params.limit
              ),
              meta: {
                total: allListMock.length,
                offset: params.offset,
                limit: params.limit,
              },
            },
          })
        })
      })
    )
  }

  connectCloudEntityList = (
    selectedIds: string[],
    dataTypeId: string,
    connectorType: string
  ): Promise<AxiosResponse<UpdateCloudFoldersListResponse>> =>
    this.patch(`ts/v2/data-connector/cloud-directories`, {
      selectedIds,
      knowledgeDataTypeId: dataTypeId,
      connectorType,
    })

  getCloudEntities$ = (
    dataConnectorId: string,
    params: VirtualListChunk & { query?: string; path?: string } & any
  ): Observable<AxiosResponse<GetConnectedEntitiesResponse>> =>
    this.getStream$(
      `ts/v2/data/data-connector/${dataConnectorId}/cloud-directories`,
      { params }
    )

  getCloudFiles$ = (
    dataConnectorId: string,
    folderId: string,
    params: VirtualListChunk & { query?: string } & any
  ): Observable<AxiosResponse<GetUploadedFilesResponse>> =>
    this.getStream$(
      `ts/data/data-connector/${dataConnectorId}/folder/${folderId}/all`,
      { params }
    )

  getDataConnectionById = (
    dataConnectorId: string
  ): Promise<AxiosResponse<GetDataConnector>> =>
    this.get(`ts/data-connector`, true, { dataConnectorId })

  uploadFiles = (
    form: FormData,
    params?: { knowledgeDataTypeId?: string }
  ): Promise<AxiosResponse<UploadFilesResponse>> =>
    this.post(`ts/file`, form, true, { params })

  connectWebsite = (
    form: ConnectWebsiteFormType
  ): Promise<AxiosResponse<ConnectWebsiteResponse>> =>
    this.post(`ts/website`, form)

  addDataConnector = (
    form: ConnectWithCodeRequest | ConnectWithTokenRequest
  ): Promise<AxiosResponse<AddDataConnectorResponse>> =>
    this.post(`ts/data-connector`, form)

  removeDataSource = (
    sourceName: DataSourceName,
    id: string
  ): Promise<AxiosResponse<DeleteDataSourceResponse>> => {
    const { sourceType, idParamName } =
      findDELETEDataSourceGroupAPIKey(sourceName)
    return this.delete(`ts/${sourceType}`, null, true, { [idParamName]: id })
  }

  getCloudFoldersList = async (
    dataConnectorId: string,
    params: PaginationParams,
    nextPageToken?: string
  ): Promise<AxiosResponse<GetCloudFoldersListResponse>> => {
    return this.get(`ts/data-connector/cloud-folders`, true, {
      dataConnectorId,
      // pageNum works as offset for this endpoint
      pageNum: (params.pageNum - 1) * params.pageSize,
      pageSize: params.pageSize,

      nextPageToken,
    })
  }

  /**
   * @returns mock of cloud-folder entities for performance testing
   */
  getCloudFoldersMockList = async (
    dataConnectorId: string,
    params: PaginationParams,
    total = 100_000
  ): Promise<{ data: GetCloudFoldersListResponse }> => {
    const timestamp = Date.now()

    const chunkSize =
      params.pageSize * params.pageNum > total
        ? total % params.pageSize
        : params.pageSize

    const offset = params.pageSize * (params.pageNum - 1)

    await delay(10, null)

    return {
      data: {
        data: initArray(chunkSize, (idx) => ({
          name: `Folder ${offset + idx}_${timestamp}`,
          cloudFolderId: nanoid(),
        })),
        meta: {
          pageNum: params.pageNum,
          pageSize: params.pageSize,
        },
      },
    }
  }

  updateCloudFoldersList = (
    dataConnectorId: string,
    folders: BasicCloudFolderEntity[],
    dataTypeId?: string
  ): Promise<AxiosResponse<UpdateCloudFoldersListResponse>> =>
    this.post(
      `ts/data-connector/cloud-folders`,
      { folders, knowledgeDataTypeId: dataTypeId },
      true,
      {
        params: {
          dataConnectorId,
        },
      }
    )

  getDataConnectorFolder = (
    folderId: string
  ): Promise<AxiosResponse<GetCloudFolderResponse>> =>
    this.get(`ts/data-connector/folder`, true, {
      folderId,
    })

  removeDataConnectorFolder = (
    folderId: string
  ): Promise<AxiosResponse<GetCloudFolderResponse>> =>
    this.delete(`ts/data-connector/folder`, null, true, {
      folderId,
    })

  removeDataConnectorEntity = (
    cloudDirectoryId: string
  ): Promise<AxiosResponse<GetCloudFolderResponse>> =>
    this.delete(`ts/v2/data-connector/cloud-directories/${cloudDirectoryId}`)

  /**
   * @deprecated used fot formal knowledge section until refactoring
   */
  getDataConnectorFileList = (
    folderId: string,
    params?: PaginationParams
  ): Promise<AxiosResponse<GetDataConnectorFilesResponse>> =>
    this.get(`ts/data-connector/folder/${folderId}/files/all`, true, params)

  getUploadedFilesGroups = (
    dataCategoryId: string
  ): Promise<AxiosResponse<GetDataSourceGroupStatuesResponse>> =>
    this.get(`ts/knowledge-data-type/${dataCategoryId}/file/all`)

  getUploadedFilesByCategoryId = (
    dataCategoryId: string,
    sourceGroup: FileDataSourceName | string,
    params?: PaginationParams
  ): Promise<AxiosResponse<GetFilesByGroupNameResponse>> =>
    this.get(
      `ts/knowledge-data-type/${dataCategoryId}/file/${sourceGroup}/all`,
      true,
      params
    )

  getWebsitesByCategoryId = (
    dataCategoryId: string,
    params?: PaginationParams
  ): Promise<AxiosResponse<GetFilesByGroupNameResponse>> =>
    this.get(
      `ts/knowledge-data-type/${dataCategoryId}/website/all`,
      true,
      params
    )

  getDataConnectorGroupsByCategoryId = (
    dataCategoryId: string
  ): Promise<AxiosResponse<GetDataSourceGroupStatuesResponse>> =>
    this.get(`ts/knowledge-data-type/${dataCategoryId}/data-connector/all`)

  getDataConnectorsByCategoryId = (
    dataCategoryId: string,
    dataConnectorName: DataConnectorSourceName
  ): Promise<AxiosResponse<GetDataConnectorByCategoryIdResponse>> =>
    this.get(
      `ts/knowledge-data-type/${dataCategoryId}/data-connector/${dataConnectorName}/all`
    )

  getDataConnectorFoldersByCategoryId = (
    dataCategoryId: string,
    dataConnectorId: string,
    params?: PaginationParams
  ): Promise<AxiosResponse<any>> =>
    this.get(
      `ts/knowledge-data-type/${dataCategoryId}/data-connector/${dataConnectorId}/folder/all`,
      true,
      params
    )

  signedDataUrl = (
    url: string
  ): Promise<AxiosResponse<GetSignedURLResponse>> => {
    return this.get(`ts/file/signed-url`, true, { url })
  }
}

export default new UploadAPI()
