import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import { useStore } from "@store/index"
import { SUBSCRIPTION_OPTIONS_KEYS } from "@framework/constants/subscription"
import Text from "@components/ui/Typography/Text"
import { renderDurationGap } from "@utils/date"

import SubscriptionHeader from "./SubscriptionHeader"
import Connectors from "./Connectors"
import SubscriptionOptions from "./SubscriptionOptions"
import EditInput from "./EditInput"
import StatusText from "./StatusText"
import ContractDates from "./ContractDates"

import styles from "./index.module.sass"

export interface SimpleTableProps {
  className?: string
}

export const SubscriptionsList: React.FC<SimpleTableProps> = observer(
  ({ className }) => {
    const { subscriptionStore, restrictionsStore: access } = useStore()
    const { isEditable, subscriptionUsage, data } = subscriptionStore

    const row = [
      {
        name: "Support",
        purchased: (
          <SubscriptionOptions optionName={SUBSCRIPTION_OPTIONS_KEYS.SUPPORT} />
        ),
      },
      {
        name: "Contract Duration",
        purchased: <ContractDates />,
        status: (
          <StatusText
            text={
              data.contractEndDate
                ? renderDurationGap(data.contractEndDate)
                : "NA"
            }
          />
        ),
      },
      {
        name: "API Quota",
        purchased: (
          <EditInput optionName={SUBSCRIPTION_OPTIONS_KEYS.API_QUOTA} />
        ),
        status: (
          <StatusText text={(data?.apiQuotaConsumed || "N/A").toString()} />
        ),
      },
      {
        name: "Data Quota",
        purchased: (
          <EditInput optionName={SUBSCRIPTION_OPTIONS_KEYS.DATA_QUOTA} />
        ),
        status:
          isEditable && access.isManualDataQuotaEnabled ? (
            <EditInput
              optionName={SUBSCRIPTION_OPTIONS_KEYS.DATA_QUOTA_CONSUMED}
            />
          ) : (
            <StatusText
              text={(`${data?.dataQuotaConsumed} GB` || "N/A").toString()}
            />
          ),
      },
      {
        name: "Connectors",
        purchased: <Connectors />,
      },
      {
        name: "Number of Users",
        purchased: (
          <EditInput optionName={SUBSCRIPTION_OPTIONS_KEYS.NUMBER_OF_USERS} />
        ),
        status: (
          <StatusText
            text={subscriptionUsage?.numberOfUsers?.toString() || "None"}
          />
        ),
      },
      {
        name: "Environments",
        purchased: (
          <SubscriptionOptions
            optionName={SUBSCRIPTION_OPTIONS_KEYS.ENVIRONMENTS}
          />
        ),
      },
      {
        name: "Platform Hosting",
        purchased: (
          <SubscriptionOptions
            optionName={SUBSCRIPTION_OPTIONS_KEYS.PLATFORM_HOSTING}
          />
        ),
      },
    ]

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.container}>
          <Table header={<SubscriptionHeader />}>
            {row.map((item) => (
              <Row className={clsx(styles.row)} key={item.name}>
                <Column
                  justify="start"
                  width="40%"
                  align={isEditable ? "center" : "start"}
                >
                  <Text variant="h5">{item.name}</Text>
                </Column>

                <Column
                  align="center"
                  justify="start"
                  colspan={item.status ? 1 : 2}
                  width={item.status ? "25%" : "50%"}
                  className={clsx({ [styles.noPadding]: isEditable })}
                >
                  {item.purchased}
                </Column>
                {item.status && (
                  <Column align="center" justify="start" width="25%">
                    {item.status}
                  </Column>
                )}
              </Row>
            ))}
          </Table>
        </div>
      </div>
    )
  }
)

export default SubscriptionsList
