import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { CompanyWithMetaInfo } from "@framework/types/company"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import { useController, useStore } from "@store"

interface ContextMenuProps {
  company: CompanyWithMetaInfo
}

const ManufacturerContextMenu: React.FC<ContextMenuProps> = observer(
  ({ company: selectedCompany }) => {
    const alert = useAlert()

    const linkPublicDataModal = useModal(ModalsTypes.LINK_PUBLIC_DATA_MODAL)
    const addManufacturerModal = useModal(ModalsTypes.ADD_MANUFACTURER_MODAL)
    const manufacturerAccessModal = useModal(
      ModalsTypes.MANUFACTURER_ACCESS_PERMISSIONS_MODAL
    )

    const { restrictionsStore: access } = useStore()

    const { companyController } = useController()

    const { isOpened, handleToggle, setOpened } = useToggle()

    const handleEdit = () => {
      addManufacturerModal.showModal({ selectedCompany })
    }

    const handleAccessPermission = () => {
      manufacturerAccessModal.showModal({
        companyId: selectedCompany.id,
        initiallyPublic: selectedCompany.isPublic,
        onClose: () => {
          companyController.requestCompaniesReload()
        },
      })
    }

    const handleManage = () => {
      linkPublicDataModal.showModal({ selectedCompany })
    }

    const handleAttributeExtraction = async () => {
      const response = await companyController.startAttributeExtraction(
        selectedCompany.id
      )

      if (response) {
        alert.info(`Attribute extraction started for ${selectedCompany.name}`)
      } else {
        alert.error(
          `Failed to start attribute extraction for ${selectedCompany.name}`
        )
      }

      setOpened(false)
    }

    return (
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer color="primary" placement="left">
            {access.canAddManufacturer && (
              <MenuItem icon="edit" onClick={handleEdit} key="edit">
                Edit
              </MenuItem>
            )}

            {access.canViewPermissions && (
              <MenuItem
                icon="lock-unlock"
                onClick={handleAccessPermission}
                key="access"
              >
                Access Permissions
              </MenuItem>
            )}

            {access.canConnectPublicData && (
              <MenuItem icon="settings" onClick={handleManage} key="manage">
                Manage link to Public Data
              </MenuItem>
            )}

            {access.canStartAttributeExtraction && (
              <MenuItem
                icon="settings"
                onClick={handleAttributeExtraction}
                key="attributeExtraction"
              >
                Start Attribute Extraction
              </MenuItem>
            )}
          </TooltipContainer>
        }
      >
        <IconButton size="medium" active={isOpened} onClick={handleToggle}>
          <Icon name="dots" />
        </IconButton>
      </Tooltip>
    )
  }
)

export default ManufacturerContextMenu
