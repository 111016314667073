import { nanoid } from "nanoid"

import CompanyAPI from "@services/company.service"
import {
  CreateCompanyRequest,
  LinkCompanyRequest,
} from "@framework/types/company"
import RootStore from "@store/RootStore"
import { SortBy } from "@framework/types/common"
import { UserAndGroup } from "@framework/types/user"
import { PaginationParams } from "@framework/types/utils"

import CompanyStore from "./company.store"

class CompanyController {
  private companyStore: CompanyStore

  constructor(injections: RootStore) {
    this.companyStore = injections.companyStore
  }

  requestCompaniesReload = () => {
    this.companyStore.triggerReload()
  }

  fetchCompanies = async (options?: SortBy | null) => {
    this.companyStore.loadingCompanies = true
    this.companyStore.error = null
    try {
      const response = await CompanyAPI.getCompanies(
        options != null
          ? {
              sortBy: options.name,
              sortOrder: options.direction ? "ASC" : "DESC",
            }
          : undefined
      )
      this.companyStore.companies = response.data?.map((company, index) => ({
        ...company,
        linkedPublicCompanies: company.linkedPublicCompanies?.filter(
          (item) => !!item
        ),
      }))
    } catch (error: any) {
      this.companyStore.error = error.message
    } finally {
      this.companyStore.loadingCompanies = false
    }
  }

  fetchTrialInstanceCompanies = async () => {
    this.companyStore.loadingCompanies = true
    this.companyStore.error = null
    try {
      const response = await CompanyAPI.getTrialInstanceCompanies()
      this.companyStore.trialInstanceCompanies = response.data
    } catch (error: any) {
      this.companyStore.error = error.message
    } finally {
      this.companyStore.loadingCompanies = false
    }
  }

  createOrUpdateCompany = async (data: CreateCompanyRequest, id?: string) => {
    this.companyStore.loading = true
    this.companyStore.error = null
    try {
      const response = await (id
        ? CompanyAPI.updateCompany(data, id)
        : CompanyAPI.createCompany(data))
      if (response.status === 200) {
        return true
      }
    } catch (error: any) {
      this.companyStore.error = error.message
    } finally {
      this.companyStore.loading = false
    }
    return false
  }

  linkCompanies = async (data: LinkCompanyRequest) => {
    this.companyStore.loading = true
    this.companyStore.error = null
    try {
      const response = await CompanyAPI.linkCompanies(data)
      if (response.status === 200) {
        return true
      }
    } catch (error: any) {
      this.companyStore.error = error.message
    } finally {
      this.companyStore.loading = false
    }
    return false
  }

  startAttributeExtraction = async (companyId: string) => {
    try {
      const response = await CompanyAPI.startAttributeExtraction(companyId)
      if (response.status === 200) {
        return true
      }
    } catch (error: any) {
      return false
    }

    return false
  }

  loadPermissions = async (
    companyId: string,
    params: PaginationParams,
    reset: boolean = false
  ): Promise<UserAndGroup[] | null> => {
    const store = this.companyStore.permissions
    try {
      store.isLading = true

      if (reset) store.entities = []

      const response = await CompanyAPI.getCompanyPermissions(companyId, params)

      store.meta = { ...response.data.meta }

      const uniqueUsersAndGroups = response.data.data
        .filter(
          (newUser) =>
            !store.entities.some(
              (existingUser) => existingUser.id === newUser.id
            )
        )
        .map((item): UserAndGroup => {
          if (item.group != null)
            return {
              ...item.group,
              type: "group",
            }

          return {
            ...item.user,
            type: "user",
          }
        })

      store.entities = [...store.entities, ...uniqueUsersAndGroups]
      return store.entities
    } catch (error: any) {
      store.error = error.message
      store.entities = []
    } finally {
      store.isLading = false
    }
    return null
  }

  reloadPermissions = () => {
    this.companyStore.permissions.reloadTrigger = nanoid()
  }

  switchAccessToPublic = async (companyId: string) => {
    const store = this.companyStore.permissions

    try {
      store.isLading = true

      await CompanyAPI.withdrawAccess(companyId, {
        makePublic: true,
      })

      return true
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.isLading = false
    }

    return false
  }

  grantAccess = async (companyIds: string[], entities: UserAndGroup[]) => {
    const store = this.companyStore.permissions

    try {
      const userIds = entities
        .filter((it) => it.type === "user")
        .map((it) => it.id)
      const groupIds = entities
        .filter((it) => it.type === "group")
        .map((it) => it.id)

      store.isLading = true

      await CompanyAPI.grantAccess({
        companyIds,
        userIds,
        groupIds,
      })

      return true
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.isLading = false
    }

    return false
  }

  withdrawAccess = async (companyId: string, entities: UserAndGroup[]) => {
    const store = this.companyStore.permissions

    try {
      const userIds = entities
        .filter((it) => it.type === "user")
        .map((it) => it.id)

      const groupIds = entities
        .filter((it) => it.type === "group")
        .map((it) => it.id)

      store.isLading = true

      await CompanyAPI.withdrawAccess(companyId, {
        userIds,
        groupIds,
        makePublic: false,
      })

      return true
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.isLading = false
    }

    return false
  }
}

export default CompanyController
