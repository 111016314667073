import React from "react"
import { observer } from "mobx-react-lite"
import difference from "lodash/difference"
import differenceBy from "lodash/differenceBy"
import { useAlert } from "react-alert"

import BaseContainer from "@components/modals/AvatarMembership/EditModal/components/BaseContainer/BaseContainer"
import Option from "@components/modals/AvatarMembership/EditModal/components/Option/Option"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import { AvatarData, AvatarDetailsData } from "@framework/types/avatar"
import { useController, useStore } from "@store/index"
import { DatatypeEditDTO } from "@framework/types/knowledge"

import ModalContainer from "../components/Container"

export interface DatatypeAssignAvatarModalProps {
  datatypeId: string
  selectedAvatars: AvatarDetailsData[]
  editModeByDefault?: boolean
  onSubmit?: () => void
}

const QUESTION_TEXT = "Are you sure you want to unassign avatar?"

const DatatypeAssignAvatarModal: React.FC<DatatypeAssignAvatarModalProps> =
  observer(({ datatypeId, editModeByDefault, selectedAvatars, onSubmit }) => {
    const [isEdit, setIsEdit] = React.useState(editModeByDefault)
    const [avatarsToAdd, setAvatarsToAdd] = React.useState<string[]>([])
    const alert = useAlert()

    const assignModal = useModal(ModalsTypes.DATATYPE_ASSIGN_AVATAR_MODAL)
    const deleteModal = useModal(
      ModalsTypes.DELETE_AVATAR_MEMBERSHIP_ITEM_MODAL
    )

    const {
      avatarController: { loadAvatars },
      knowledgeDatatypesController: { editDatatype },
    } = useController()

    const {
      avatarsStore: { avatars, isAvatarsLoading },
      datatypesStore: { isLoading },
      knowledgeDatatypeDetailsStore: { isLoading: isDatatypeLoading },
    } = useStore()

    React.useEffect(() => {
      loadAvatars()
    }, [])

    const items = React.useMemo(
      () =>
        isEdit
          ? differenceBy(avatars, selectedAvatars ?? [], "id")
          : selectedAvatars ?? [],
      [avatars, isEdit]
    )

    const updateDatatype = async (payload: Partial<DatatypeEditDTO>) => {
      const error = await editDatatype(datatypeId, payload)
      if (error) {
        alert.error(error)
      }

      onSubmit?.()
    }

    const handleRemoveConfirm = async (id: string) => {
      await updateDatatype({ avatarIdsToDelete: [id] })

      onSubmit?.()
    }

    const handleSubmit = async () => {
      await updateDatatype({ avatarIds: avatarsToAdd })
      setIsEdit(false)
    }

    const handleOptionClick = (id: string) => {
      if (isEdit) {
        setAvatarsToAdd((prev) =>
          prev.includes(id) ? difference(prev, [id]) : [...prev, id]
        )
        return
      }

      deleteModal.showModal({
        question: QUESTION_TEXT,
        onSubmit: () => handleRemoveConfirm(id),
      })
    }

    const renderOption = (item: AvatarData) => (
      <Option
        key={item.id}
        avatarUrl={item.imageUrl}
        label={item.name}
        checked={avatarsToAdd.includes(item.id)}
        metaInfo={
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: item?.description ?? "-" }}
          />
        }
        onClick={() => handleOptionClick(item.id)}
        isEdit={isEdit}
      />
    )

    return (
      <ModalContainer
        title={
          <ModalTitle titleText={`${isEdit ? "Add" : "Assigned"} Avatars`} />
        }
        onClose={assignModal.hideModal}
      >
        <BaseContainer<AvatarData>
          items={items}
          entityKey="Avatars"
          renderer={renderOption}
          onSubmit={handleSubmit}
          isLoading={isAvatarsLoading || isLoading || isDatatypeLoading}
          isEditing={isEdit}
          onEdit={(value) => setIsEdit(value)}
          addLength={avatarsToAdd.length}
        />
      </ModalContainer>
    )
  })

export default DatatypeAssignAvatarModal
