import React from "react"
import clsx from "clsx"

import Button, { ButtonProps } from "@components/ui/Button/Button"
import Icon, { IconProps } from "@components/ui/Icon/Icon"

import styles from "./VoiceInputButton.module.sass"

export interface VoiceInputButtonProps extends ButtonProps<"button"> {
  listening?: boolean
}

export const VoiceInputButton: React.FC<VoiceInputButtonProps> = ({
  listening,
  variant = "slim",
  noPadding = true,
  className,
  ...rest
}) => {
  return (
    <Button
      title={listening ? "Stop Recording" : "Tap for Speech"}
      className={clsx(styles.controlButton, className)}
      variant={variant}
      color={listening ? "primary" : "default"}
      noPadding={noPadding}
      {...rest}
    >
      <VoiceInputIcon listening={listening} />
    </Button>
  )
}

export interface VoiceInputIconProps extends IconProps {
  listening?: boolean
}

export const VoiceInputIcon: React.FC<VoiceInputIconProps> = ({
  listening,
  className,
  ...rest
}) => {
  return (
    <Icon
      {...rest}
      className={clsx({ [styles.recording]: listening }, className)}
      name={listening ? "record-circle" : "mic-fill"}
    />
  )
}

export default VoiceInputButton
