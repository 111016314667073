import React from "react"
import { Outlet } from "react-router-dom"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import mainRoutes from "@root/main.routes"
import { AppMediaQueries } from "@framework/constants/app"
import useMediaQuery from "@components/hooks/useMediaQuery"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import Chip from "@components/ui/Chip/Chip"
import BackButton from "@components/prototypes/BackButton"

import FactFinderPage from "./SolutionTabsContainer/components/Tabs/FactFinder"
import { useSolution } from "./ActiveSolutionContext"

import styles from "./LaunchSolutions.module.sass"

const SearchSolution: React.FC = observer(() => {
  const { solution } = useSolution()
  const { avatar } = useActiveAvatar()

  const {
    searchSuggestionStore: { loadQuestions },
    factFinderSolutionStore: { searchFlowStore },
  } = useStore()

  const {
    appConfigController: { loadAllInstanceConfigurations },
  } = useController()

  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    })
  }, [])

  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    })
  }, [searchFlowStore.flowSequence.length])

  React.useEffect(() => {
    loadAllInstanceConfigurations()
    loadQuestions(avatar.id, solution.id)
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!solution) return null
  return (
    <div className={clsx(styles.root)}>
      <Header />

      <div className={styles.body}>
        <FactFinderPage data={solution} />
      </div>
      <Outlet />
    </div>
  )
})

const Header: React.FC = observer(() => {
  const { solution } = useSolution()

  const isTablet = useMediaQuery(AppMediaQueries.minTablet)
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

  const editSearchPresetModal = useModal(
    ModalsTypes.EDIT_SEARCH_SOLUTION_PRESET_MODAL
  )

  const {
    restrictionsStore: access,
    searchSuggestionStore: { favoriteQuestions },
    factFinderSolutionStore: { searchFlowStore },
    solutionsStore,
  } = useStore()

  const { factFinderSolutionController } = useController()

  const handleReset = () => {
    searchFlowStore.reset()
    searchFlowStore.isFlowReset = true
    factFinderSolutionController.clearHighLights()
  }

  const handlePresetEdit = () => {
    editSearchPresetModal.showModal({ solution })
  }

  const handleRecentActivity = () => {
    solutionsStore.showRecentActivity = true
  }

  const buttonSize = isMobile ? "tiny" : isTablet ? "small" : "medium"

  return (
    <Templates.Header
      className={styles.header}
      left={
        <div className={styles.backButtonWrapper}>
          <BackButton
            to={mainRoutes.home()}
            before={<Icon name="line-arrow-down" rotateAngle={90} />}
            size={buttonSize}
          >
            {solution.name}
          </BackButton>
          {solution.isBeta && (
            <Chip variant="rounded" color="green">
              Beta
            </Chip>
          )}
        </div>
      }
      right={
        <>
          {!!searchFlowStore?.flowSequence?.length && (
            <Button
              size={buttonSize}
              color="primary"
              disabled={searchFlowStore?.isAnyLoading()}
              onClick={handleReset}
              className={styles.newChatButton}
              before={<Icon name="plus" />}
            >
              {!isMobile && "Start new Chat"}
            </Button>
          )}

          {access.canCreateSolution && (
            <Button
              size={buttonSize}
              color={isMobile ? "default" : "secondary"}
              variant={isMobile ? "text" : "contained"}
              onClick={handlePresetEdit}
              before={<Icon name="standards" />}
            >
              {!isMobile && "Edit Preset"}
            </Button>
          )}

          {!!favoriteQuestions.length && (
            <Button
              size={buttonSize}
              color={isMobile ? "default" : "secondary"}
              variant={isMobile ? "text" : "contained"}
              onClick={handleRecentActivity}
              before={<Icon name="retry" />}
            >
              {!isMobile && "Recent Activity"}
            </Button>
          )}
        </>
      }
    />
  )
})

export default SearchSolution
