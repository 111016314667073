import * as React from "react"
import clsx from "clsx"

import Icon from "../Icon/Icon"
import { MinimalInput } from "./MinimalInput"
import Loader from "../Loader/BarLoader"
import { useDropdownContext } from "./DropdownContext"
import DropdownArrow from "./DropdownArrow"

import styles from "./Select.module.sass"

export const DropdownTarget: React.FC<
  React.PropsWithChildren<{
    selected: boolean
    withError?: boolean
    className?: string
    placeholder?: string
    onReset?: () => void
  }>
> = ({
  className,
  children,
  selected,
  placeholder,
  withError,
  onReset,
  ...rest
}) => {
  const context = useDropdownContext()

  const { searchQuery, setSearchQuery } = context

  const inputRef = React.useRef<HTMLInputElement>(null)

  const disableInput = !(
    (context.searchable || context.creatable) &&
    (context.isMulti || context.open)
  )

  const showPlaceholder = !selected || (!context.isMulti && context.open)

  return (
    <div
      data-active={context.open}
      className={clsx(
        styles.target,
        { [styles.disabled]: context.disabled },
        { [styles.withError]: withError },
        className
      )}
      {...rest}
      {...context.interactions.getReferenceProps({
        ref: context.floating.refs.setReference,
        "aria-labelledby": "select-label",
        "aria-autocomplete": "none",
        onMouseDown() {
          inputRef.current?.focus()
        },
        onFocus() {
          inputRef.current?.focus()
        },
        onKeyDown(event) {
          if (event.key === "Enter") {
            if (context.open) context.onSelectByIndex(context.activeIndex ?? -1)

            if (context.closeOnSelect || !context.open)
              context.onOpenChange(!context.open)

            event.preventDefault()
          }
        },
      })}
    >
      <div className={styles.content}>
        <>{!context.open || context.isMulti ? children : null}</>

        <MinimalInput
          ref={inputRef}
          tabIndex={0}
          placeholder={showPlaceholder ? placeholder : ""}
          className={styles.input}
          value={searchQuery}
          disabled={context.disabled}
          style={{
            caretColor: disableInput ? "transparent" : undefined,
          }}
          onChange={(e) => {
            if (!disableInput) setSearchQuery?.(e.currentTarget?.value || "")
            context.onOpenChange(true)
          }}
        />
      </div>

      <div className={styles.controlContainer}>
        {context.loading ? (
          <Loader size="small" />
        ) : (
          <>
            {Boolean(selected && context.clearable) && (
              <Icon
                className={styles.controlIcon}
                name="cross"
                onMouseDown={(e) => {
                  if (e.button !== 0) return
                  onReset?.()
                }}
              />
            )}
          </>
        )}
      </div>

      <DropdownArrow />
    </div>
  )
}

export default DropdownTarget
