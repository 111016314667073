import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import {
  combineLatest,
  from,
  ObservableInput,
  of,
  Subscription,
  timer,
} from "rxjs"
import { switchMap, tap, retry } from "rxjs/operators"
import { toStream } from "mobx-utils"
import sortBy from "lodash/sortBy"

import { searchBy } from "@utils/optionsUtils"
import {
  DataSourceNodeContextProps,
  getDataSourceNodeContextProps,
} from "@framework/constants/upload"
import {
  dataConnectorsNames,
  fileSourcesNames,
  webSiteNames,
} from "@framework/types/upload"
import RestrictionsStore from "@store/restrictions/restrictions.store"

const RETRY_DELAY = 30 * 1000

const allDataSourceOptions = [
  ...dataConnectorsNames,
  ...fileSourcesNames,
  ...webSiteNames,
]

type State = {
  isLoading: boolean
  data: DataSourceNodeContextProps[]
  total: number
  errorMessage: string
  searchQuery: string
  reloadTrigger: number
}

export class AllDataSourcesStore {
  state: State

  private restrictions: RestrictionsStore

  private loadingStream$?: Subscription

  constructor(injections: { restrictions: RestrictionsStore }) {
    this.state = {
      isLoading: false,

      data: [],

      total: 0,

      searchQuery: "",

      errorMessage: "",

      reloadTrigger: Date.now(),
    }

    this.restrictions = injections.restrictions

    makeAutoObservable(this)

    onBecomeObserved(this.state, "total", () => {
      this.loadingStream$ = this.initLoadingStream().subscribe()
    })

    onBecomeUnobserved(this.state, "total", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    const { state } = this

    const loadStream$ = from(
      toStream(
        () => [this.restrictions.testDataSourceAccess, state.reloadTrigger],
        true
      ) as ObservableInput<
        [(dataSources: string | string[]) => boolean, boolean]
      >
    ).pipe(
      tap(() =>
        runInAction(() => {
          state.isLoading = true
          state.errorMessage = ""
        })
      ),
      switchMap(([testAccess]) => {
        return of(
          sortBy(
            allDataSourceOptions
              .filter(testAccess)
              .filter((sourceName) => sourceName !== "sharepoint")
              .map(getDataSourceNodeContextProps),
            (it) => it.label
          )
        )
      })
    )

    const filterStream$ = from(
      toStream(() => [state.searchQuery], true) as ObservableInput<[string]>
    )

    return combineLatest([loadStream$, filterStream$]).pipe(
      tap(([items, [searchQuery]]) =>
        runInAction(() => {
          state.data = searchBy(items, searchQuery, (it) => it.label)
          state.data.push(getDataSourceNodeContextProps("public"))
          state.total = state.data.length
          state.isLoading = false
        })
      ),
      retry({
        delay: () => {
          runInAction(() => {
            state.errorMessage = "Loading Failed"
          })
          return timer(RETRY_DELAY)
        },
      })
    )
  }

  search = (query: string) => {
    this.state.searchQuery = query
  }

  refresh = () => {
    this.state.reloadTrigger = Date.now()
  }

  get getByIndex() {
    const { data } = this.state
    return (index: number) => {
      return data[index]
    }
  }
}

export default AllDataSourcesStore
