import React from "react"
import clsx from "clsx"

import Icon from "../Icon/Icon"
import Column, { ColumnProps } from "./Column"
import { useTableOrderContext } from "./TableOrderContext"

import styles from "./HeaderCell.module.sass"

export interface HeaderCellProps extends ColumnProps {
  name?: string
  direction?: boolean
}

export const HeaderCell: React.FC<HeaderCellProps> = React.memo(
  ({ as: Component = "td", children, className, name, onClick, ...rest }) => {
    const order = useTableOrderContext()

    const isSortable = name != null
    const isSortedBy = order.sortBy != null && name === order.sortBy.name

    return (
      <Column
        role="button"
        as="th"
        className={clsx({ [styles.clickable]: isSortable }, className)}
        onClick={
          isSortable
            ? (e) => {
                order.toggleOrder(name)
                onClick?.(e)
              }
            : onClick
        }
        {...rest}
      >
        <div className={clsx(styles.root, { [styles.sortable]: isSortable })}>
          <span className={styles.content}>{children}</span>
          {isSortable && (
            <span className={styles.arrow}>
              <Icon
                name="arrow-down"
                style={{ visibility: isSortedBy ? "visible" : "hidden" }}
                rotateAngle={order.sortBy?.direction ? 0 : 180}
              />
            </span>
          )}
        </div>
      </Column>
    )
  }
)

export default HeaderCell
